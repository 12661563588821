'use client'
import { Image } from '@chakra-ui/next-js'
import { Button, Box } from '@chakra-ui/react'
import { RiKakaoTalkLine } from 'react-icons/ri'

export default function KakaoTalk() {
  // 클릭시 "http://pf.kakao.com/_fwrLGj"로 이동하는 버튼

  const handleClick = () => {
    window.open('https://pf.kakao.com/_fwrLG')
  }

  return (
    <Box
      position="fixed"
      bottom="20px"
      right="20px"
      onClick={handleClick}
      zIndex="1000">
      <Button
        // backgroundColor="#FEE500"
        rounded="full" // 버튼을 완전한 원으로 만듭니다
        width="60px" // 버튼의 너비를 고정합니다
        height="60px" // 버튼의 높이를 고정하여 원 형태를 유지합니다
        p={0} // 버튼 내부의 기본 패딩을 제거하여 아이콘이 중앙에 위치하도록 합니다
        boxShadow="lg"
        _hover={{ boxShadow: 'xl' }}>
        <Image
          src="https://imagedelivery.net/6ZZEpKdXI04JJCvPE__Mog/04dbd902-b74c-4ffd-be3c-d710c6c2c800/public"
          alt="KakaoTalk Icon"
          width={63}
          height={63}
        />
        {/* <RiKakaoTalkLine
          onClick={handleClick}
          color="#3C1E1E"
          size={'40'}
        /> */}
      </Button>
    </Box>
  )
}

// app/providers.tsx
'use client'

import Navigation from '@/components/navigation'
import { CacheProvider } from '@chakra-ui/next-js'
import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect } from 'react'
import { bubbleCursor } from 'cursor-effects'

import { Poppins, Noto_Sans_KR } from 'next/font/google'

const poppins = Poppins({
  subsets: ['latin'],
  variable: '--font-poppins',
  weight: ['400', '500', '600', '700'],
  display: 'swap',
})

const notoSansKR = Noto_Sans_KR({
  subsets: ['cyrillic'],
  variable: '--font-noto-sans-kr',
  display: 'swap',
})

export const fonts = {
  poppins,
  notoSansKR,
}

declare module 'cursor-effects' {
  export class bubbleCursor {
    constructor(options: { length: number; size: number; colors?: string[] })
  }
}

const client = new QueryClient()

export function Providers({ children }: { children: React.ReactNode }) {
  const theme = extendTheme({
    colors: {
      primary: '#ED6D03',
      'header-black': '#212121',
      'main-bgColor': '#ffffff',
      'main-textColor': '#212B36',
    },
    styles: {
      global: {
        body: {
          bg: '#ffffff', // 원하는 배경색으로 변경
          color: '#212B36', // 기본 텍스트 색상
        },
      },
    },
    fonts: {
      heading: `${poppins.style.fontFamily}, ${notoSansKR.style.fontFamily}`, // Rubik 폰트를 우선 적용, 그 후 Noto Sans KR
      body: `${poppins.style.fontFamily}, ${notoSansKR.style.fontFamily}`, // 마찬가지로 Rubik 우선 적용
    },
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
  })

  useEffect(() => {
    new bubbleCursor({
      length: 20,
      size: 4,
    } as any) // 타입 명시를 피하기 위한 any
  }, [])

  return (
    <CacheProvider>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={client}>{children}</QueryClientProvider>
      </ChakraProvider>
    </CacheProvider>
  )
}
